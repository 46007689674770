<template>
  <lazy-cart-entwash-order-cancel
    :order-cancel-event="orderCancelEvent"
    v-if="appConfig.VueSettingsPreRun.CartLayout === 'Entwash'"
  />
</template>

<script setup lang="ts">
import type { EmitterEvent } from '~types/common'

const appConfig = useAppConfig()
const clientStore = useClientStore()
const orderCancelEvent: EmitterEvent = 'v-order-cancel'
const { eventOn } = useEmitter()

onMounted(async () => {
  if (import.meta.client) await clientStore.initOrdersForCancel()

  eventOn(orderCancelEvent, () => {
    clientStore.loadOrdersForCancel()
  })
})
</script>
